// src/utils/axios.js
import axios from "axios";
import { getStorage } from "../utils/util";
const baseUrl = process.env.NODE_ENV === "development" ? "https://192.168.85.221/v1" : window.location.origin + "/v1";

const instance = axios.create({
  baseURL: baseUrl, // 设置基础 URL
  timeout: 10000, // 设置请求超时时间
  // 其他配置...
});


// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    try {
      const token = getStorage("token");
      // 在发送请求之前做些什么，例如设置 token
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.token = token;
      return config;
    } catch {}
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    if (response.status === 200) {
        const {data} = response
        if (data && data.code === 0){
            return Promise.resolve(data);
        }else{
            return Promise.reject({errorMsg:data.error,code:data.code});
        }
    }
  },
  (error) => {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default instance;

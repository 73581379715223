import zhMessages from "../messages/zh.json";
import zhCN from "antd/es/locale/zh_CN";

const Lan_zh = {
  messages: {
    ...zhMessages,
  },
  antd: zhCN,
  locale: "zh-CN",
};

export default Lan_zh;

// features/counter/apiSlice.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../api/axios';

// 定义一个异步 thunk
export const fetchSiteList = createAsyncThunk(
  'siteList/fetchReq',
  async () => {
    const response = await instance.get('/site-info');
    return response.data;
  }
);
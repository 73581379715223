import { createSlice } from "@reduxjs/toolkit";
import AppLocal from "../../assets/language/index";
  
  const initLocale = "en_US";
  
  const initialState = {
    // curLocal:AppLocal["en_US"],
    curLocal: AppLocal[initLocale],
    Lan: initLocale,
  };
  
  export const localeSlice = createSlice({
    name: "Locale",
    initialState,
    reducers: {
      // Use the PayloadAction type to declare the contents of `action.payload`
      changeLocale: (state, action) => {
        state.curLocal = AppLocal[action.payload];
        state.Lan = action.payload;
      },
    },
  });
  
  export const { changeLocale } = localeSlice.actions;

  const localeReducer = localeSlice.reducer
  
  export default localeReducer;